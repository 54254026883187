@use 'theme.scss' as custom-theme;

@import 'app/shared-styles/fonts';

@import 'app/shared-styles/colors';
@import '~animate.css/animate.min.css';
@import 'placeholder-loading/dist/css/placeholder-loading.min.css';

$ph-animation-duration: 0.8s !default;

ngx-spinner {
    height: 100%;
}

.cdk-overlay-container {
    z-index: 11111111 !important;
}

app-navbar {
    z-index: 10;
    box-shadow: 0px 1px 4px #808080c4;
}

.button-with-icon {
    .mat-icon {
        height: 1rem;
        width: 1rem;
        font-size: 1rem;
        line-height: 1rem;
        margin-right: 0.2rem;
        color: $medium-gray;
    }
}

.no-margin {
    margin: 0 !important;
}

//@media only screen and (orientation:portrait){
//  body {
//    height: 100vw;
//    transform: rotate(90deg);
//  }
//}

.signika {
    font-family: 'Signika', sans-serif;
}

img.emoji {
    height: 1em;
    width: 1em;
    margin: 0 0.05em 0 0.1em;
    vertical-align: -0.1em;
}

html {
    --mdc-filled-text-field-container-color: #171717;
    --mdc-dialog-container-color: #141414;
}

.mat-mdc-dialog-container .mdc-dialog__surface {
    border: 1px solid #1f1f1f;
}

html,
body {
    height: 100%;
    color: #fff;
}

body {
    margin: 0;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    //background: rgb(93, 53, 176);
    //background: linear-gradient(315deg, rgba(93, 53, 176, 1) 0%, rgba(222, 74, 49, 1) 100%);
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -100% 0;
    }
    100% {
        background-position: 100% 0;
    }
}

.animated-placeholder {
    animation-duration: 1.25s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #6c6c6c;
    background: linear-gradient(to right, #6c6c6c 10%, #646464 18%, #6c6c6c 33%);
    background-size: 200% 100%; /* Adjust the background size to ensure the gradient can move fully */
    position: relative;
}

.row {
    display: flex;
    flex-direction: row;
}

.row-centered {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.time-display {
    user-select: none;
    margin-left: 4px;
    display: flex;
    justify-content: center;
    height: 50px;
    cursor: pointer;
    color: #fff;
    line-height: 50px;
    pointer-events: none;
    font-family:
        Helvetica Neue,
        Helvetica,
        Arial,
        sans-serif;
}

.column-centered {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.color-picker {
    box-shadow: 0px 0px 3px #2c2c2c;
    background-color: #424242 !important;
    border-radius: 8px;

    .box > div {
        color: white !important;
    }

    .saturation-lightness {
        border-radius: 8px;
    }
}

.primary {
    color: $shuffll-purple;
}

.hideme {
    visibility: hidden;
}

.hideme-soft {
    opacity: 0;
}

.white {
    color: white !important;
}

//Relative font size
html {
    font-size: 18px;
}

.responsive-dialog mat-dialog-container {
    position: relative;
}

.mat-mdc-dialog-container,
.mat-dialog-container {
    height: auto !important;
    max-height: 90vh !important;
    border-radius: 20px !important;
}

@media (max-width: 1600px) {
    html {
        font-size: 17px !important;
    }
}

.top-most-backdrop {
    background: #393939e3;
    z-index: 10000 !important;
}

.top-most-backdrop + .cdk-global-overlay-wrapper {
    z-index: 10001 !important;
}

.top-most {
    z-index: 10001 !important;
    height: 100vh;
    width: 100vw !important;
    max-width: none !important;

    .mat-mdc-dialog-container,
    .mat-dialog-container {
        max-height: 100vh !important;

        background: linear-gradient(315deg, rgba(93, 53, 176, 1) 0%, rgba(222, 74, 49, 1) 100%) !important;
    }
}

//@media (max-width: 1600px) {
//  html {
//    font-size: 15px;
//  }
//}
@media only screen and (max-width: 1024px) {
    .responsive-dialog {
        max-width: 90vw !important;
        width: 90vw !important;

        .mat-mdc-dialog-container,
        .mat-dialog-container {
            padding: 1vh;
            height: 95vh;
        }
    }

    // make the stepper scrollable
    .mat-horizontal-stepper-header-container {
        overflow-x: hidden;
    }

    .mat-horizontal-stepper-header {
        min-width: fit-content;
    }
    //
    //.format-cat-options {
    //  flex-basis: auto !important;
    //}
}

.flex-button {
    &.row {
        .mdc-button__label {
            flex-direction: row;
        }
    }

    &.row-reverse {
        .mdc-button__label {
            flex-direction: row-reverse;
        }
    }

    &.column {
        .mdc-button__label {
            flex-direction: column;
        }
    }

    .mdc-button__label {
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media only screen and (max-width: 760px) {
    //Used for stream-setup dialog
    .mat-mdc-dialog-container,
    .mat-dialog-container {
        padding: 1vh;
    }
    .responsive-dialog {
        max-width: 95vw !important;
        width: 95vw !important;

        // make the stepper scrollable
        .mat-horizontal-stepper-header-container {
            overflow-x: hidden;
        }

        .mat-horizontal-stepper-header {
            min-width: fit-content;
        }

        .cards-list-item-wrapper {
            flex-basis: auto !important;
        }
    }
}

@media (max-width: 900px) {
    html {
        font-size: 14px;
    }
}

@media (max-width: 400px) {
    html {
        font-size: 12px;
    }
}

.small-margin-right {
    margin-right: 5px;
}

.mat-body,
.mat-body-1 .mat-body-2,
.mat-typography {
    font-size: 0.7rem !important;
}

/* Type will scale with document */
h1,
.biggest {
    font-size: 1.4rem !important;
}

h2,
.bigger {
    font-size: 1.2rem !important;
}

h3,
.big {
    font-size: 1.1rem !important;
}

h4,
.regular {
    font-size: 1rem !important;
}

h5,
.smaller {
    font-size: 0.75rem !important;
}

h6,
.smallest {
    font-size: 0.6rem !important;
}

.no-border-radius {
    border-radius: 0;
}

.carousel {
    border-radius: 10px;
}

.videoplayer {
    border-radius: 10px !important;

    video {
        border-radius: 10px;
    }

    .controls {
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}

.vjs-modal-dialog {
    border-radius: 10px !important;
}

.canvas-container {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    //width: 100% !important;
    //height: 100% !important;
}

.clickable {
    cursor: pointer;
}

.link:hover {
    color: #5677d5;
}

.link-static {
    color: #5677d5;
}

// vg
.rounded {
    border-radius: 10px;
}

.border-radius-small {
    border-radius: 4px;
}

.scrubBar {
    outline: none;
}

////
#page-wrap {
    display: flex;
    width: 100%;
    height: 100vh;
}

/// ANimations for the main component - did not work when there on prod build.

.blink_me {
    animation: blinker 1.5s linear infinite;
    -webkit-animation: blinker 1.5s linear infinite;
    -o-animation: blinker 1.5s linear infinite;
}

@keyframes blinker {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes blinker {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes blinker {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes blinker {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.show-after-delay {
    animation: show-delay 1s ease-in 3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    -webkit-animation: show-delay 1s ease-in 3s;
    -o-animation: show-delay 1s ease-in 3s;
    -webkit-animation-name: show-delay;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-delay: 3s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-name: show-delay;
    -moz-animation-duration: 1s;
    -moz-animation-iteration-count: 1;
    -moz-animation-delay: 3s;
    -moz-animation-timing-function: linear;
    -moz-animation-fill-mode: forwards;
    -o-animation-name: show-delay;
    -o-animation-duration: 1s;
    -o-animation-iteration-count: 1;
    -o-animation-delay: 3s;
    -o-animation-timing-function: linear;
    -o-animation-fill-mode: forwards;
}

.show-after-small-delay {
    animation: show-delay 1s ease-in 1.5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    -webkit-animation: show-delay 1s ease-in 1.5s;
    -o-animation: show-delay 1s ease-in 1.5s;
    -webkit-animation-name: show-delay;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-delay: 1.5s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-name: show-delay;
    -moz-animation-duration: 1s;
    -moz-animation-iteration-count: 1;
    -moz-animation-delay: 1.5s;
    -moz-animation-timing-function: linear;
    -moz-animation-fill-mode: forwards;
    -o-animation-name: show-delay;
    -o-animation-duration: 1s;
    -o-animation-iteration-count: 1;
    -o-animation-delay: 1.5s;
    -o-animation-timing-function: linear;
    -o-animation-fill-mode: forwards;
}

@keyframes show-delay {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-webkit-keyframes show-delay {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-o-keyframes show-delay {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@-moz-keyframes show-delay {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.ease-in-after-delay {
    animation: show-delay 1s ease-in 5s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    -webkit-animation: show-delay 1s ease-in 5s;
    -o-animation: show-delay 1s ease-in 5s;
    -webkit-animation-name: show-delay;
    -webkit-animation-duration: 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-delay: 5s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-fill-mode: forwards;
    -moz-animation-name: show-delay;
    -moz-animation-duration: 1s;
    -moz-animation-iteration-count: 1;
    -moz-animation-delay: 5s;
    -moz-animation-timing-function: linear;
    -moz-animation-fill-mode: forwards;
    -o-animation-name: show-delay;
    -o-animation-duration: 1s;
    -o-animation-iteration-count: 1;
    -o-animation-delay: 5s;
    -o-animation-timing-function: linear;
    -o-animation-fill-mode: forwards;
    opacity: 0;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-button-wrapper {
    display: flex;
    /* justify-content: center; */
    align-items: center;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.mat-mdc-icon-button .mat-button-wrapper,
.mat-icon-button .mat-button-wrapper {
    justify-content: center;
}

// TOASTR
.toast-bottom-right {
    bottom: 60px !important;
}

.stretch-badge > .mat-badge-content {
    width: auto;
    min-width: 22px;
    border-radius: 13px;
    //right: unset !important;
}

//#toast-container>.toast-success {
//  background-image: url("./assets/images/demo/live-2.jpg")!important;
//  padding: 216px 0px 0px 5px;
//  width: 310px;
//  min-height: 249px;
//  background-position: center;
//  background-position-y: 4px;
//  background-size: 302px;
//  border-radius: 0;
//  background-color: #1d1d1d96 !important;
//  color: #ffffff;
//
//}

.toast-success {
}

vg-controls {
    flex-direction: column;
    padding-left: 5px;
    padding-right: 5px;
    box-sizing: border-box;
    height: auto !important;
    background-color: transparent !important;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.67) 0%, rgba(0, 0, 0, 0.38) 10%, transparent 80%);
    pointer-events: initial;
    padding: 0 10px;
    box-sizing: border-box;

    .play-button {
        width: 40px !important;
        height: 40px !important;
        line-height: 32px !important;
        padding: 8px !important;
    }

    &.hide {
        app-scrub-bar {
            opacity: 0;
            transition:
                opacity 0.3s,
                visibility 0.3s;
        }
    }

    app-scrub-bar {
        width: 100%;
        padding: 0 10px;
        box-sizing: border-box;
        margin-top: 10px;

        mat-slider-visual-thumb {
            left: -9px !important;
            height: 18px !important;
            width: 18px !important;
        }

        .mdc-slider {
            height: 16px;
        }

        .mat-mdc-slider .mdc-slider__track--active_fill {
            background: $cool-gradient;
            border: none;
            border-color: none;
        }
    }

    .bottom-row {
        //margin-top: 4px;
        height: 40px;

        button {
            color: white;
        }

        > * {
            max-height: 40px;
        }
    }
}

vg-controls vg-scrub-bar .background {
    background: $cool-gradient;
    border-color: $cool-gradient !important;
    border: none !important;
}

vg-controls vg-scrub-bar {
    position: relative !important;
    top: 0 !important;
    pointer-events: all !important;
    background: #0000002b !important;
    cursor: pointer;
    padding: 0;
    margin: 0;
}

.mat-mdc-menu-panel.video-comment-menu,
.mat-menu-panel.video-comment-menu {
    max-width: none;
    display: flex;
    padding: 0 8px;
    background: #1d1d1d;
}

vg-scrub-bar-cue-points .cue-point-container .cue-point {
    background-color: #9696964a !important;
    //background-color: #983d3ba1 !important;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
       supported by Chrome, Edge, Opera and Firefox */
}

.small-icon {
    font-size: 22px !important;
    height: 22px !important;
    width: 22px !important;
    line-height: 22px !important;
}

.smaller-icon {
    font-size: 16px !important;
    height: 16px !important;
    width: 16px !important;
    line-height: 16px !important;
}

.super-small-icon {
    font-size: 8px !important;
    height: 8px !important;
    width: 8px !important;
    line-height: 8px !important;
}

.small-icon-button {
    font-size: 12px !important;
    height: 30px !important;
    width: 30px !important;
    line-height: 30px !important;
    padding: 5px !important;

    .mat-icon {
        font-size: 20px !important;
        height: 20px !important;
        width: 20px !important;
        line-height: 20px !important;
    }
}

.slighly-small-icon-button {
    font-size: 12px !important;
    height: 26px !important;
    width: 26px !important;
    line-height: 26px !important;
    padding: 4px !important;

    .mat-icon {
        font-size: 18px !important;
        height: 18px !important;
        width: 18px !important;
        line-height: 18px !important;
    }
}

.smaller-icon-button {
    font-size: 12px !important;
    height: 12px !important;
    width: 12px !important;
    line-height: 12px !important;
    padding: 0px !important;

    .mat-icon {
        font-size: 12px !important;
        height: 12px !important;
        width: 12px !important;
        line-height: 12px !important;
    }
}

.super-small-icon-button {
    font-size: 8px !important;
    height: 8px !important;
    width: 8px !important;
    line-height: 8px !important;
    padding: 2px !important;

    .mat-icon {
        font-size: 8px !important;
        height: 8px !important;
        width: 8px !important;
        line-height: 8px !important;
    }
}

.color-shuffll {
    color: $shuffll-purple;
}

.color-shuffll-semi {
    color: $shuffll-red-bright;
}

.color-shuffll-bright {
    color: $shuffll-light-red;
}

.background-shuffll {
    background-color: $shuffll-red;
}

/////// style scrollbars    /////

/* The emerging W3C standard
   that is currently Firefox-only */
* {
    scrollbar-width: thin;
    scrollbar-color: $light-gray $main-app-bg;
}

*.black-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: $dark-gray;
}

*.transparent-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
}

/* Works on Chrome/Edge/Safari */
*::-webkit-scrollbar {
    width: 12px;
}

*.black-scrollbar::-webkit-scrollbar-track {
    background: $main-app-bg;
}

*::-webkit-scrollbar-thumb {
    background-color: $light-gray;
    border-radius: 20px;
    border: 3px solid $main-app-bg;
}

*.black-scrollbar::-webkit-scrollbar-thumb {
    background-color: $dark-gray;
    border-radius: 20px;
    border: 1px solid $main-app-bg;
}

.cdk-overlay-dark-backdrop {
    background: rgba(0, 0, 0, 0.85);
    filter: blur(10px);
}

.menu-title {
    color: #2d2d2d;
    padding: 12px 20px;
    font-weight: bold;
    //background: #9f9f9f;
    //border: 2px solid #858585;
}

// overwrite default mat flex behavior
.mat-grid-tile .mat-figure {
    position: static !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
}

html,
body,
#main-pane {
    max-width: 100%;
    overflow-x: hidden;
}

editable {
    .input-with-edit-icon {
        display: flex;
        align-items: center;
        gap: 10px;

        &:hover .edit-icon {
            opacity: 0.7;
        }

        .edit-icon {
            opacity: 0;
            transition: opacity 0.2s;
        }
    }

    &.disabled-edit {
        cursor: default !important;
    }
}

.cool-button {
    --mdc-fab-container-shape: 35%;
    --mdc-fab-small-container-shape: 35%;
}

.cool-button.gray {
    background-color: $alpha-medium-gray-60-calculated;
}

.snackbar {
    text-align: center;
}

.cool-snackbar {
    --mdc-snackbar-container-color: black;
    --mdc-snackbar-supporting-text-color: white;
    --mdc-snackbar-supporting-text-size: 1.4em;
    --mdc-snackbar-container-shape: 12px;

    .mdc-snackbar__surface {
        min-width: auto !important;
    }
}

editable.minimal-editable {
    .bordered {
        border: 1px solid transparent;
    }

    input {
        background: transparent;
        color: white;
        outline: transparent;

        border: none;

        line-height: inherit;
        font-size: inherit;
        letter-spacing: inherit;
        padding: 0;
        width: 100%;
        text-decoration: underline;
        text-decoration-color: #3d3d3d;
        text-decoration-thickness: 1px;
        text-underline-offset: 4px;
    }
}

.slick-track {
    margin-left: 0 !important;
}

.mat-mdc-dialog-panel {
    box-shadow: 0px 0px 11px -4px #3c3c3c;
}

.padding-modal .mat-mdc-dialog-surface {
    padding: 30px;
}

.padding-modal .mdc-dialog__container {
    min-width: 100% !important;
}

.padding-modal .container-flex {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    min-height: 100%;
}

.row-spacebetween {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* styles.css or component.css */
.light-theme-input {
    .mdc-text-field--filled {
        background-color: #f5f5f5 !important; /* White background */
        color: $dark-gray;
        border-bottom: 1px solid #ccc; /* Light grey border */

        input {
            color: $dark-gray !important;
        }

        & mat-label {
            color: #666; /* Dark gray placeholder text */
        }
    }
}

.align-center {
    text-align: center;
}

.streams-slider .slick-arrow {
    position: absolute;
    top: calc(50% - 30px);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 60px;
    text-align: center;
    opacity: 1;
    z-index: 9;
    background: rgba(0, 0, 0, 0.5);
    margin: 0;
    line-height: 5px;
    box-shadow: 0px 9px 19px #01041b0d;
    font-size: 0;
    color: #ffffff;
    transition: all 0.4s ease-in-out 0s;

    &:hover {
        cursor: pointer;
    }

    & > svg {
        height: 19px;
    }
}

.streams-slider .prev {
    right: auto;
    left: 0;
    z-index: 9;

    & > svg {
        transform: rotate(180deg);
    }
}

.streams-slider .next {
    right: 18px;
}

button.icon-last {
    display: flex;
    flex-direction: row-reverse;

    mat-icon {
        margin-right: -4px !important;
        margin-left: 8px !important;
    }
}

.mat-mdc-button,
.mat-mdc-icon-button,
.mat-mdc-outlined-button,
.mat-mdc-unelevated-button,
.mat-mdc-raised-button .mat-button,
.mat-icon-button,
.mat-outlined-button,
.mat-unelevated-button,
.mat-raised-button {
    font-size: 0.8rem;
    line-height: 1.2rem !important;
    height: 1.8rem;
    padding: 0rem 0.3rem;
}

.bold {
    font-weight: bold !important;
}

.wrap-text {
    overflow-wrap: break-word;
    word-wrap: break-word;
}

.force-hidden {
    display: none;
}

.transparent-grid {
    background: #ffffff
        url('data:image/svg+xml,\
   <svg xmlns="http://www.w3.org/2000/svg" width="400" height="400"  fill="%23C5C5C5"  fill-opacity=".1" >\
            <rect x="200" width="200" height="200" />\
            <rect y="200" width="200" height="200" />\
            </svg>');
    background-size: 25px 25px;
    margin-bottom: 20px;
    aspect-ratio: 1.7777777;
    overflow: hidden;
    width: 100%;
}

.csfl_white image {
    //filter: brightness(100%) saturate(0%);
    //filter: brightness(10000%) invert(0);
    filter: contrast(0) brightness(0) invert(1);
}

.csfl_black image {
    //filter: brightness(0%) saturate(0%);
    //filter: brightness(10000%);
    filter: contrast(0) brightness(0);
}

.hidden {
    visibility: hidden;
    display: none;
}

.aspect-ratio-box {
    overflow: hidden;

    //&.ratio-9x16 {
    //  height: 100%;
    //}
    //
    //&.ratio-1x1,
    //&.ratio-4x3,
    //&.ratio-16x9 {
    //  width: 100%;
    //}
}

.unselectable {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;

    /*
Introduced in Internet Explorer 10.
See http://ie.microsoft.com/testdrive/HTML5/msUserSelect/
*/
    -ms-user-select: none;
    user-select: none;
}

.mat-drawer-backdrop {
    background-color: rgba(0, 0, 0, 0.8) !important;
}

.mat-drawer {
    --mat-sidenav-container-background-color: #141414;
}

.no-padding {
    padding: 0;
}

.toast-container {
    border-radius: 8px;
    overflow: hidden;
    background: #474747;

    .toast-progress {
        left: 10px;
        border-radius: 2px;
    }
}

.toast-bottom-left {
    bottom: 12px !important;
    left: 82px !important;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
       supported by Chrome, Edge, Opera and Firefox */
}

.dummy-button {
    padding: 0;
    width: 0px;
    height: 0px;
    opacity: 0;
}

dynamic-lottie {
    //  Dont allow text selection
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                     supported by Chrome, Edge, Opera and Firefox */
}

.gapped {
    gap: 8px;
}

.big-gapped {
    gap: 24px;
}
.spinner-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    overflow: hidden;
}

/// NEVER DELETE _ FOR LOTTIE
.stroke-first {
    paint-order: stroke;
}

.shuffll-green {
    color: #98ce00;
}

.shuffll-bright-purple {
    color: #9d8af9;
}
.no-click {
    pointer-events: none;
}
