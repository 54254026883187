@import '~typeface-roboto/index.css';
@import '~typeface-signika/index.css';
@import '~material-icons/css/material-icons.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
    font-family: 'Major League';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/major-league/Major-LeagueDuty.eot'); /* For IE6-8 */
    src:
        local('Major League Duty'),
        local('Major League Duty'),
        url('../../assets/fonts/major-league/Major-LeagueDuty.woff2') format('woff2'),
        url('../../assets/fonts/major-league/Major-LeagueDuty.ttf') format('truetype'),
        url('../../assets/fonts/major-league/Major-LeagueDuty.woff') format('woff');
}

@font-face {
    font-family: 'Berlin Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../../assets/fonts/berlin-sans/BRLNSR.TTF'); /* For IE6-8 */
    src:
        local('Berlin Sans'),
        local('Berlin Sans'),
        url('../../assets/fonts/berlin-sans/BRLNSR.TTF') format('truetype');
}
